import { handleResponse } from '../helpers/HandleResponse';
import {
  AuthenticateInterface,
  ChallengeInterface,
  ChallengeReqInterface,
  ChallengeResponseInterface,
  CheckInterface,
  StateInterface,
  StateQuestionsInterface,
} from '../interfaces/Identity.d';
import config from './../config';

export const IdentityService = {
  getToken,
  expungeToken,
  check,
  authenticate,
  getChallenge,
  verifyChallenge,
  forgot,
  resetPassword,
  translateQuestions,
  getState,
  createMFA,
  verifyMFA,
  deleteMFA,
  upsertQuestions,
  updatePassword,
};

function getToken() {
  return localStorage.getItem('token');
}
function addToken(token: string) {
  localStorage.setItem('token', token);
}
function expungeToken() {
  localStorage.removeItem('type');
  localStorage.removeItem('token');
}

//Performs the check for the identity
async function check(emailAddress: string, hostUrl: string) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return await fetch(`${config.api}/identity?email=${emailAddress}&host=${hostUrl}&useIdentity=true`, requestOptions)
    .then(handleResponse)
    .then((a: CheckInterface) => {
      return a;
    });
}

//Performs the basic authentication
async function authenticate(emailAddress: string, password: string) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email: emailAddress,
      password: password,
    }),
  };

  return await fetch(`${config.api}/identity/challenge`, requestOptions)
    .then(handleResponse)
    .then((a: AuthenticateInterface) => {
      return a;
    });
}

async function getChallenge(token: string) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };

  return await fetch(`${config.api}/identity/challenge?token=${token}`, requestOptions)
    .then(handleResponse)
    .then((a: ChallengeInterface) => {
      return a;
    });
}

async function verifyChallenge(payload: ChallengeReqInterface) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  };

  return await fetch(`${config.api}/identity/challenge`, requestOptions)
    .then(handleResponse)
    .then((a: ChallengeResponseInterface) => {
      addToken(a.token);
      return a;
    });
}

async function forgot(emailAddress: string) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      emailAddress: emailAddress,
    }),
  };

  return await fetch(`${config.api}/identity/forgot?env=2`, requestOptions)
    .then((response) => {
      if (response.status === 204) {
        return true;
      } else {
        return false;
      }
    })
    .catch((error) => {
      return false;
    });
}

async function resetPassword(emailAddress: string, password: string, code: string) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      emailAddress: emailAddress,
      password: password,
      resetCode: code,
    }),
  };

  return await fetch(`${config.api}/identity/forgot`, requestOptions)
    .then((response) => {
      if (response.status === 204) {
        return true;
      } else {
        return false;
      }
    })
    .catch((error) => {
      return false;
    });
}

function translateQuestions(question: number) {
  switch (question) {
    case 0:
      return 'None';
    case 1:
      return 'What city were you born in?';
    case 2:
      return "What is your oldest sibling's middle name?";
    case 3:
      return "What was the first concert you've attended?";
    case 4:
      return 'What was the make and model of your first car?';
    case 5:
      return 'In what city or town did your parents meet?';
  }
}

async function getState(password: string) {
  if (getToken()) {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
    };

    return await fetch(`${config.api}/identity/setup?password=${password}`, requestOptions)
      .then(handleResponse)
      .then((a: StateInterface) => {
        return a;
      });
  } else {
    return Promise.reject();
  }
}

async function createMFA(password: string, secret: string, type: number) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
    body: JSON.stringify({
      secret: secret,
      type: type,
    }),
  };

  return await fetch(`${config.api}/identity/setup?password=${password}`, requestOptions)
    .then((response) => {
      if (response.status === 204) {
        return true;
      } else {
        return false;
      }
    })
    .catch((error) => {
      return false;
    });
}

async function verifyMFA(password: string, code: string) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
  };

  return await fetch(`${config.api}/identity/setup/verify?password=${password}&code=${code}`, requestOptions)
    .then((response) => {
      if (response.status === 204) {
        return true;
      } else {
        return false;
      }
    })
    .catch((error) => {
      return false;
    });
}

async function deleteMFA(password: string) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
  };

  return await fetch(`${config.api}/identity/setup?password=${password}`, requestOptions)
    .then((response) => {
      if (response.status === 204) {
        return true;
      } else {
        return false;
      }
    })
    .catch((error) => {
      return false;
    });
}

async function upsertQuestions(password: string, questions: StateQuestionsInterface[]) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
    body: JSON.stringify(questions),
  };

  return await fetch(`${config.api}/identity/setup?password=${password}`, requestOptions)
    .then((response) => {
      if (response.status === 204) {
        return true;
      } else {
        return false;
      }
    })
    .catch((error) => {
      return false;
    });
}

async function updatePassword(emailAddress: string, oldPassword: string, newPassword: string) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
    body: JSON.stringify({
      emailAddress: emailAddress,
      oldPassword: oldPassword,
      newPassword: newPassword,
    }),
  };

  return await fetch(`${config.api}/identity/password`, requestOptions)
    .then(handleResponse)
    .then((a: any) => {
      return { valid: !!a.errorMessage, errorMessage: a.errorMessage };
    })
    .catch((error) => {
      return {
        valid: false,
        errorMessage: 'There was a problem updating your password. Please reload the page and try again.',
      };
    });
}
