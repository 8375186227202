import React from 'react';
import './ActionButton.scss';

export default function ActionButton({
  type = '', //submit, none
  className = '',
  disabled = false,
  label = '',
  ...props
}) {
  return type === 'submit' ? (
    <input className={`action-button ${className}`} type="submit" disabled={disabled} value={label} {...props} />
  ) : (
    <></>
  );
}
