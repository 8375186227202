export function handleResponse(response: any) {
  const statusCode = response.status;

  if (statusCode !== 200 && statusCode !== 204) {
    return Promise.reject(statusCode);
  } else {
    try {
      return response.text().then((text: any) => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
          return Promise.reject(data);
        }

        return { ...data, statusCode };
      });
    } catch (ex) {
      return Promise.reject(ex);
    }
  }
}
