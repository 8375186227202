import TriggerButton from '../../../components/Buttons/TriggerButton';
import config from '../../../config';
import { StateInterface } from '../../../interfaces/Identity.d';

export default function Menu({
  password,
  state,
  onChange,
}: {
  password: string;
  state: StateInterface | null;
  onChange: (mode: number) => void;
}) {
  return (
    <>
      <h2>Change Password</h2>
      <span className="left">Change your account login password.</span>
      <div className="actions flat">
        <TriggerButton
          label="Change Password"
          theme="light"
          disabled={false}
          onClick={() => {
            onChange(6);
          }}></TriggerButton>
      </div>
      <div className="line"></div>
      <h2>Two-Factor Authentication</h2>
      <span className="left">
        We use two-factor authentication to add a second layer of security protection to your account.
      </span>
      <div className="actions flat">
        {(state?.type ?? 0) > 0 && (
          <TriggerButton
            label="Remove 2FA"
            theme="red"
            disabled={false}
            onClick={() => {
              onChange(4);
            }}></TriggerButton>
        )}
        {(state?.type ?? 0) === 0 && (
          <TriggerButton
            label="Setup 2FA"
            theme="light"
            disabled={false}
            onClick={() => {
              onChange(2);
            }}></TriggerButton>
        )}
      </div>
      <div className="line"></div>
      <h2>Security Questions</h2>
      <span className="left">
        Add additional security questions to your account, that only you know the answer to as a secondary layer to
        access your account.
      </span>
      <div className="actions flat">
        <TriggerButton
          label="Set / Update Questions"
          theme="light"
          disabled={false}
          onClick={() => {
            onChange(5);
          }}></TriggerButton>
      </div>
      <div className="line"></div>
      <div className="actions">
        <TriggerButton
          label="Back to BraveGen"
          theme="default"
          disabled={false}
          onClick={() => {
            //Redirect back.
            window.location.href = `${config.hub}/Profile?view=security`;
          }}></TriggerButton>
      </div>
    </>
  );
}
