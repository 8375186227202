import { useEffect, useState } from 'react';
import { StateInterface, StateQuestionsInterface } from '../../../interfaces/Identity.d';
import TriggerButton from '../../../components/Buttons/TriggerButton';
import { SelectOptionNumeric } from '../../../interfaces/Common.d';
import { IdentityService } from '../../../services/Identity.Service';
import Select from 'react-select';
import ActionButton from '../../../components/Buttons/ActionButton';

export default function Questions({
  password,
  state,
  onChange,
}: {
  password: string;
  state: StateInterface | null;
  onChange: (m: number, s: StateInterface | null) => void;
}) {
  const [buttonText, setButtonText] = useState<string>('Save');
  const [errorText, setErrorText] = useState<string>('');
  const [options, setOptions] = useState<SelectOptionNumeric[]>([]);
  const [questions, setQuestions] = useState<StateQuestionsInterface[]>(state?.questions ?? []);

  useEffect(() => {
    const check = () => {
      if ((state?.questions ?? []).length === 0) {
        //Initalize
        let items: StateQuestionsInterface[] = [];
        [...Array(4)].map((a, b) => items.push({ question: 0, answer: '' }));
        setQuestions(items);
      } else {
        setQuestions(state?.questions ?? []);
      }
    };

    buildOptions();
    check();
  }, [state]);

  const buildOptions = () => {
    let items: SelectOptionNumeric[] = [];

    [...Array(5)].map((a, b) =>
      items.push({
        value: b + 1,
        label: `${IdentityService.translateQuestions(b + 1)}`,
      })
    );

    setOptions(items);
  };

  function validate() {
    if (questions.every((x) => x.question !== 0 && x.answer !== '') && questions.length === 4) {
      return true;
    } else {
      return false;
    }
  }

  async function handleSaving(event: any) {
    event.preventDefault();

    //Reset state
    setButtonText('Saving...');
    setErrorText('');

    //Push the changes through
    var result = await IdentityService.upsertQuestions(password, questions);

    if (result) {
      //Successful - Update state and redirect back to menu.
      onChange(1, {
        result: 1,
        errorMessage: '',
        email: `${state?.email}`,
        secret: `${state?.secret}`,
        type: state?.type ?? 0,
        questions: questions,
      });
    } else {
      //Failed
      setErrorText('Sorry, we were unable to save your selections at this time. Please reload and try again.');
      setButtonText('Save');
    }
  }

  const QuestionSelect = ({
    val,
    items,
    quests,
    onClick,
  }: {
    val: number;
    items: SelectOptionNumeric[];
    quests: StateQuestionsInterface[];
    onClick: (x: SelectOptionNumeric | null) => void;
  }) => {
    return (
      <Select
        className="custom-select"
        classNamePrefix="cs"
        options={items.filter((x) => !quests.map((a) => a.question).includes(x.value) || x.value === val)}
        defaultValue={items.find((x) => x.value === val)}
        placeholder="Select a question..."
        onChange={onClick}
      />
    );
  };

  return (
    <>
      <span className="no-pad">
        Set your security questions for your account that only you know the answers to.
        <br />
        <br />
        Please complete all questions and responses below.
      </span>
      <div className="line"></div>
      <form onSubmit={handleSaving}>
        {questions.map((a, b) => (
          <div className="group" key={b}>
            <QuestionSelect
              val={a.question}
              items={options}
              quests={questions}
              onClick={(x) => {
                if (x) {
                  let arr = [...questions];
                  let inst = arr.find((xx) => xx === a);
                  if (inst) {
                    inst.question = x.value;
                    setQuestions(arr);
                  }
                }
              }}
            />
            <div className="spacer"></div>
            <input
              className="input"
              type="text"
              placeholder="Your answer..."
              value={a.answer}
              onChange={(x) => {
                if (x) {
                  let arr = [...questions];
                  let inst = arr.find((xx) => xx === a);
                  if (inst) {
                    inst.answer = x.target.value;
                    setQuestions(arr);
                  }
                }
              }}
            />
          </div>
        ))}
        <div className="line"></div>
        {errorText && <span className="error">{errorText}</span>}
        <div className="actions">
          <ActionButton
            type="submit"
            className="full-width offset"
            disabled={!validate()}
            label={buttonText}></ActionButton>
          <TriggerButton
            label="Cancel"
            theme="light"
            disabled={false}
            onClick={() => {
              onChange(1, state);
            }}></TriggerButton>
        </div>
      </form>
    </>
  );
}
