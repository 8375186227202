import TriggerButton from '../../components/Buttons/TriggerButton';
import config from '../../config';
import { useIdentity } from '../../state/Identity.Hook';
import './Welcome.scss';
import { IdentityService } from '../../services/Identity.Service';
import { useEffect } from 'react';

export default function Welcome() {
  const identity = useIdentity();

  useEffect(() => {
    document.title = 'BraveGen | Welcome';
  }, []);

  return (
    <div className="box">
      <img src="/resources/Lion-Full.png" className="animate" alt="BraveGen" />
      <div className="inner animate welcome">
        <span>
          It looks like you're already signed in with BraveGen.
          <br />
          <br />
          Select one of the following options below to proceed.
        </span>
        <div className="actions">
          <TriggerButton
            label="View BraveGen"
            theme="default"
            disabled={false}
            onClick={() => {
              const ref = localStorage.getItem('ref');
              const type = localStorage.getItem('type');
              if (ref && type === '2') {
                window.location.href = `${ref}/federated?t=${identity.token}`;
              } else {
                window.location.href = `${config.hub}/federated?t=${identity.token}`;
              }
            }}></TriggerButton>
          <TriggerButton
            label="Sign out"
            theme="light"
            disabled={false}
            onClick={() => {
              //Purge state
              IdentityService.expungeToken();
              window.location.href = `${config.hub}/SignOut`;
            }}></TriggerButton>
        </div>
      </div>
    </div>
  );
}
