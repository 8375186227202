import { useEffect } from 'react';
import { IdentityService } from '../../services/Identity.Service';

export default function SignOut() {
  useEffect(() => {
    IdentityService.expungeToken();
    window.location.href = '/';
  }, []);

  return <span></span>;
}
