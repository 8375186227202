import { useState } from 'react';
import ActionButton from '../../../components/Buttons/ActionButton';
import { useFormFields } from '../../../helpers/FormFields';
import { IdentityService } from '../../../services/Identity.Service';
import { ChallengeReqInterface } from '../../../interfaces/Identity.d';
import config from '../../../config';
import { useNavigate } from 'react-router-dom';

export default function Prompt({ token, questions }: { token: string; questions: number[] }) {
  const history = useNavigate();
  const [mode, setMode] = useState<number>(1);
  const [buttonText, setButtonText] = useState<string>('Confirm');
  const [fields, handleFieldChange] = useFormFields({ code: '', answer1: '', answer2: '' });

  const greeting = () => {
    var time = new Date().getHours();

    if (time < 12) {
      return 'Good Morning!';
    }
    if (time >= 17) {
      return 'Good Evening!';
    }
    if (time >= 12) {
      return 'Good Afternoon!';
    }
    return '';
  };

  function validate() {
    if (mode === 1) {
      return fields.code.length === 6;
    } else {
      return fields.answer1.length > 0 && fields.answer2.length > 0;
    }
  }

  async function handleChallenge(event: any) {
    event.preventDefault();

    //Only allow if button click is in Confirm state
    if (buttonText === 'Confirm') {
      //Reset state
      setButtonText('Confirming...');

      //Push
      await IdentityService.verifyChallenge(build())
        .then((a) => {
          //Redirect to Hub
          const ref = localStorage.getItem('ref');
          const type = localStorage.getItem('type');
          if (ref && type === '2') {
            window.location.href = `${ref}/federated?t=${a.token}`;
          } else {
            window.location.href = `${config.hub}/federated?t=${a.token}`;
          }
        })
        .catch((a) => {
          //Reject
          history('/Login?f=1');
        });
    }
  }

  function build(): ChallengeReqInterface {
    var model: ChallengeReqInterface = {
      token: token,
      code: '',
      answers: [],
    };

    if (mode === 1) {
      model.code = `${fields.code}`;
      return model;
    } else {
      if (questions.length === 2) {
        model.answers.push({
          question: questions[0],
          answer: `${fields.answer1}`,
        });
        model.answers.push({
          question: questions[1],
          answer: `${fields.answer2}`,
        });
      }

      return model;
    }
  }

  return (
    <>
      <h1>{greeting()}</h1>
      {mode === 1 && <span>Please enter the code found in your authenticator app.</span>}
      {mode === 2 && <span>Please provide the answers to the security questions listed below.</span>}
      {(mode === 1 || mode === 2) && (
        <form onSubmit={handleChallenge}>
          {mode === 1 && (
            <div className="group">
              <p>Authenticator Code:</p>
              <input
                id="code"
                className="input"
                autoFocus
                type="text"
                value={fields.code}
                onChange={handleFieldChange}
              />
            </div>
          )}
          {mode === 2 && questions.length === 2 && (
            <>
              <div className="group">
                <p>{IdentityService.translateQuestions(questions[0])}:</p>
                <input
                  id="answer1"
                  className="input"
                  autoFocus
                  type="text"
                  value={fields.answer1}
                  onChange={handleFieldChange}
                />
              </div>
              <div className="group">
                <p>{IdentityService.translateQuestions(questions[1])}:</p>
                <input id="answer2" className="input" type="text" value={fields.answer2} onChange={handleFieldChange} />
              </div>
            </>
          )}
          <p className="link">
            {mode === 1 && questions.length === 2 && (
              <a
                href="#0"
                onClick={() => {
                  setMode(2);
                }}>
                Use security questions instead?
              </a>
            )}
            {mode === 2 && (
              <a
                href="#0"
                onClick={() => {
                  setMode(1);
                }}>
                Back to authenticator prompt
              </a>
            )}
          </p>
          <ActionButton
            type="submit"
            className="full-width offset"
            disabled={!validate()}
            label={buttonText}></ActionButton>
        </form>
      )}
    </>
  );
}
