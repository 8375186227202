import { useState } from 'react';
import ActionButton from '../../../components/Buttons/ActionButton';
import TriggerButton from '../../../components/Buttons/TriggerButton';
import { useFormFields } from '../../../helpers/FormFields';
import { IdentityService } from '../../../services/Identity.Service';
import { StateInterface } from '../../../interfaces/Identity.d';

export default function Change({
  password,
  state,
  onChange,
}: {
  password: string;
  state: StateInterface | null;
  onChange: (mode: number) => void;
}) {
  const [fields, handleFieldChange] = useFormFields({ password1: '', password2: '' });
  const [buttonText, setButtonText] = useState<string>('Change');
  const [errorText, setErrorText] = useState<string>('');

  async function handleChange(event: any) {
    event.preventDefault();

    //Run thru validation
    if (validate() && buttonText === 'Change') {
      //Update state
      setButtonText('Changing...');

      const result = await IdentityService.updatePassword(`${state?.email}`, password, fields.password1);

      if (result.errorMessage) {
        setButtonText('Change');
        setButtonText(result.errorMessage);
      } else {
        //Completed
        setErrorText('');
        onChange(0);
      }
    }
  }

  function validate() {
    if (fields.password1.length >= 8) {
      //Length is ok.
      if (fields.password1 === fields.password2) {
        //Passwords match! - Check Complexity
        var strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');

        if (strongRegex.test(fields.password1)) {
          //Passed complexity.
          setErrorText('');
          return true;
        } else {
          setErrorText('Your password must have a mix of upper case and lower case and 1 number.');
          return false;
        }
      } else {
        //Passwords don't match
        setErrorText('Both passwords does not match.');
        return false;
      }
    } else {
      //Length is insufficient.
      setErrorText('Your password needs to be at least 8 characters in length.');
      return false;
    }
  }

  return (
    <>
      <span className="no-pad">
        To change your password, ensure that your new password has a minimum length of 8 characters and contains a
        number.
      </span>
      <form onSubmit={handleChange}>
        <div className="group">
          <p>New Password:</p>
          <input
            id="password1"
            className="input"
            autoFocus
            type="password"
            value={fields.password1}
            onChange={handleFieldChange}
          />
        </div>
        <div className="group">
          <p>Confirm Password:</p>
          <input
            id="password2"
            className="input"
            type="password"
            value={fields.password2}
            onChange={handleFieldChange}
          />
        </div>
        <span className="error">{errorText}</span>
        <div className="actions">
          <ActionButton type="submit" className="full-width offset" label={buttonText}></ActionButton>
          <TriggerButton
            label="Cancel"
            theme="light"
            disabled={false}
            onClick={() => {
              onChange(1);
            }}></TriggerButton>
        </div>
      </form>
    </>
  );
}
