import { useState } from 'react';
import ActionButton from '../../../components/Buttons/ActionButton';
import { useFormFields } from '../../../helpers/FormFields';
import { IdentityService } from '../../../services/Identity.Service';
import TriggerButton from '../../../components/Buttons/TriggerButton';
import config from '../../../config';
import { StateInterface } from '../../../interfaces/Identity.d';

export default function Authenticate({
  onComplete,
}: {
  onComplete: (password: string, state: StateInterface) => void;
}) {
  const [fields, handleFieldChange] = useFormFields({ password: '' });
  const [buttonText, setButtonText] = useState<string>('Continue');
  const [errorText, setErrorText] = useState<string>('');

  function validateLogin() {
    return fields.password.length > 0;
  }

  async function handleLogin(event: any) {
    event.preventDefault();

    //Reset and loading state.
    setButtonText('Processing...');
    setErrorText('');

    await IdentityService.getState(fields.password)
      .then((a: StateInterface) => {
        onComplete(fields.password, a);
      })
      .catch((b) => {
        setErrorText("Sorry, the credentials you've supplied is invalid.");
        setButtonText('Continue');
      });
  }

  return (
    <>
      <h1>Confirm your password</h1>
      <span>Before you can setup or amend your security options, we need you to confirm your password.</span>
      <form onSubmit={handleLogin}>
        <div className="group">
          <p>Password:</p>
          <input
            id="password"
            className="input"
            autoFocus
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </div>
        <span className="error">{errorText}</span>
        <div className="actions">
          <ActionButton
            type="submit"
            className="full-width offset"
            disabled={!validateLogin()}
            label={buttonText}></ActionButton>
          <TriggerButton
            label="Cancel"
            theme="light"
            disabled={false}
            onClick={() => {
              //Redirect back.
              window.location.href = `${config.hub}/Profile?view=security`;
            }}></TriggerButton>
        </div>
      </form>
    </>
  );
}
