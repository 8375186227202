import { useEffect, useState } from 'react';
import ActionButton from '../../components/Buttons/ActionButton';
import '../../styles/box.scss';
import { IdentityService } from '../../services/Identity.Service';
import { Link, useNavigate } from 'react-router-dom';
import { useFormFields } from '../../helpers/FormFields';
import './Login.scss';

export default function Login() {
  const history = useNavigate();
  const reason = new URLSearchParams(window.location.search).get('f');
  const [fields, handleFieldChange] = useFormFields({ email: '', password: '' });
  const [verified, setVerified] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<string>('Next');
  const [errorText, setErrorText] = useState<string>('');
  const errorMsg = [
    "Sorry, the credentials you've supplied is invalid.",
    'Oh dear, it looks like your account is suspended. Please try again later.',
    "Sorry, we're experiencing technical difficulties at the moment. Please try again later.",
    "Sorry, it looks like you're not yet setup within Hub just yet. Please contact your Administrator and try again later.",
  ];

  useEffect(() => {
    if (reason === '1') {
      setErrorText('Sorry, the authentication challenge attempt was not successful.');
    }
    document.title = 'BraveGen | Sign In';
  }, [reason]);

  const greeting = () => {
    var time = new Date().getHours();

    if (time < 12) {
      return 'Good Morning!';
    }
    if (time >= 17) {
      return 'Good Evening!';
    }
    if (time >= 12) {
      return 'Good Afternoon!';
    }
    return '';
  };

  function validateLogin() {
    if (verified) {
      return fields.email.length > 0 && fields.password.length > 0;
    } else {
      return fields.email.length > 0;
    }
  }

  async function handleLogin(event: any) {
    event.preventDefault();

    //Reset and loading state.
    setButtonText('Processing...');
    setErrorText('');

    if (verified) {
      //Verified
      await IdentityService.authenticate(fields.email, fields.password)
        .then(async (a) => {
          if (a.result === 1) {
            //Authorised
            setButtonText('Redirecting...');
            history(`/Challenge?flow=2&t=${a.token}`);
          } else {
            //Failed
            setErrorText(errorMsg[0]);
            setButtonText('Login');
          }
        })
        .catch((b) => {
          setErrorText(errorMsg[0]);
          setButtonText('Login');
        });
    } else {
      //Not verified - Perform check
      await IdentityService.check(fields.email, encodeURIComponent(window.location.origin))
        .then(async (a) => {
          if (a.federated) {
            //Federated - redirect
            setButtonText('Redirecting...');
            window.location.href = a.url;
          } else {
            //Update flags
            setVerified(true);
            setButtonText('Login');
          }
        })
        .catch((b) => {
          setErrorText("Sorry we've encountered an error. Please try again later.");
          setButtonText('Next');
        });
    }
  }

  return (
    <div className="box">
      <img src="/resources/Lion-Full.png" className="animate" alt="BraveGen" />
      <div className="inner animate login">
        <h1>{greeting()}</h1>
        <span>Enter your details below to get started.</span>
        <form onSubmit={handleLogin}>
          <div className="group">
            <p>Email Address:</p>
            <input
              id="email"
              className="input"
              autoFocus
              type="email"
              value={fields.email}
              onChange={handleFieldChange}
            />
          </div>
          {verified ? (
            <>
              <div className="group">
                <p>Password:</p>
                <input
                  id="password"
                  className="input"
                  type="password"
                  value={fields.password}
                  onChange={handleFieldChange}
                />
              </div>
              <span className="error">{errorText}</span>
              <p className="forgot">
                <Link to="/Forgot">Forgot your password?</Link>
              </p>
            </>
          ) : (
            <>
              <span className="error">{errorText}</span>
            </>
          )}
          <ActionButton
            type="submit"
            className="full-width offset"
            disabled={!validateLogin()}
            label={buttonText}></ActionButton>
        </form>
      </div>
    </div>
  );
}
