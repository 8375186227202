import { useState } from 'react';
import TriggerButton from '../../../components/Buttons/TriggerButton';
import { StateInterface } from '../../../interfaces/Identity.d';
import { IdentityService } from '../../../services/Identity.Service';

export default function Delete({
  password,
  state,
  onChange,
}: {
  password: string;
  state: StateInterface | null;
  onChange: (mode: number, state: StateInterface | null) => void;
}) {
  const [buttonText, setButtonText] = useState<string>('Yes, Remove');

  async function handleDeletion() {
    setButtonText('Processing...');

    const result = await IdentityService.deleteMFA(password);

    if (result) {
      onChange(1, {
        result: 1,
        errorMessage: '',
        email: `${state?.email}`,
        secret: `${state?.secret}`,
        type: 0,
        questions: state?.questions ?? [],
      });
    } else {
      setButtonText('Yes, Remove');
    }
  }

  return (
    <>
      <span>Are you sure you wish to remove two factor authentication from your profile?</span>
      <div className="actions">
        <TriggerButton
          label={buttonText}
          theme="red"
          disabled={false}
          onClick={() => {
            handleDeletion();
          }}></TriggerButton>
        <TriggerButton
          label="Cancel"
          theme="light"
          disabled={false}
          onClick={() => {
            onChange(1, state);
          }}></TriggerButton>
      </div>
    </>
  );
}
