import { useState } from 'react';
import ActionButton from '../../../components/Buttons/ActionButton';
import { useFormFields } from '../../../helpers/FormFields';
import { IdentityService } from '../../../services/Identity.Service';
import TriggerButton from '../../../components/Buttons/TriggerButton';

export default function Verify({ password, onChange }: { password: string; onChange: (mode: number) => void }) {
  const [fields, handleFieldChange] = useFormFields({ code: '' });
  const [buttonText, setButtonText] = useState<string>('Verify');
  const [errorText, setErrorText] = useState<string>('');

  function validate() {
    return fields.code.length === 6;
  }

  async function handleVerification(event: any) {
    event.preventDefault();

    //Reset and loading state.
    setButtonText('Processing...');
    setErrorText('');

    const result = await IdentityService.verifyMFA(password, fields.code);

    if (result) {
      //Successful
      onChange(1);
    } else {
      fields.code = '';
      setButtonText('Verify');
      setErrorText('Sorry, that did not work. Please check and try again.');
    }
  }

  return (
    <>
      <span className="no-pad">
        Now we need to verify that your new two factor authentication is working properly.
        <br />
        <br />
        Please enter the code as displayed in <strong>Microsoft Authenticator</strong> below.
      </span>
      <form onSubmit={handleVerification}>
        <div className="group">
          <p>Confirm code:</p>
          <input id="code" className="input" autoFocus type="text" value={fields.code} onChange={handleFieldChange} />
        </div>
        <span className="error">{errorText}</span>
        <div className="actions">
          <ActionButton
            type="submit"
            className="full-width offset"
            disabled={!validate()}
            label={buttonText}></ActionButton>
          <TriggerButton
            label="Cancel"
            theme="light"
            disabled={false}
            onClick={() => {
              onChange(4);
            }}></TriggerButton>
        </div>
      </form>
    </>
  );
}
