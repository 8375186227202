import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormFields } from '../../helpers/FormFields';
import { IdentityService } from '../../services/Identity.Service';
import ActionButton from '../../components/Buttons/ActionButton';

export default function Reset() {
  const history = useNavigate();
  const token = new URLSearchParams(window.location.search).get('t');
  const email = new URLSearchParams(window.location.search).get('e');
  const [errorText, setErrorText] = useState<string>('');
  const [buttonText, setButtonText] = useState<string>('Change your Password');
  const [fields, handleFieldChange] = useFormFields({ password: '', confirmed: '' });
  const [successful, setSuccessful] = useState<boolean>(false);

  useEffect(() => {
    if (token === null) {
      history('/Login');
    }
    document.title = 'BraveGen | Reset Password';
  }, [history, token]);

  const greeting = () => {
    var time = new Date().getHours();

    if (time < 12) {
      return 'Good Morning!';
    }
    if (time >= 17) {
      return 'Good Evening!';
    }
    if (time >= 12) {
      return 'Good Afternoon!';
    }
    return '';
  };

  async function handleReset(event: any) {
    event.preventDefault();

    if (buttonText !== 'Processing...') {
      //Reset and loading state.
      setButtonText('Processing...');
      setErrorText('');

      const result = await IdentityService.resetPassword(email ?? '', fields.password, token ?? '');

      if (result) {
        setButtonText('Updated!');
        setSuccessful(true);
      } else {
        setErrorText(
          "We're sorry, but we were unable to reset your password at this time. Please make sure your new password complies with the password complexity described above."
        );
        setButtonText('Change your Password');
      }
    }
  }

  function validateReset() {
    return fields.password.length > 7 && fields.confirmed.length > 7 && fields.password === fields.confirmed;
  }

  return (
    <div className="box">
      <img src="/resources/Lion-Full.png" className="animate" alt="BraveGen" />
      <div className="inner animate login">
        <h1>{greeting()}</h1>
        {!successful && (
          <>
            <span>
              You can now reset your Hub credentials for <strong>{email}</strong>.
              <br />
              <br />
              Please note that your new password must be at least 8 characters in length, contain at least 1 number, 1
              uppercase &amp; 1 special characters.
            </span>
            <form onSubmit={handleReset}>
              <div className="group">
                <p>Your new Password:</p>
                <input
                  id="password"
                  className="input"
                  autoFocus
                  type="password"
                  value={fields.password}
                  onChange={handleFieldChange}
                />
              </div>
              <div className="group">
                <p>Confirm Password again:</p>
                <input
                  id="confirmed"
                  className="input"
                  type="password"
                  value={fields.confirmed}
                  onChange={handleFieldChange}
                />
              </div>
              <span className="error">{errorText}</span>
              <p className="forgot">
                <a
                  href="#0"
                  onClick={() => {
                    history('/Login');
                  }}>
                  Remembered your password?
                </a>
              </p>
              <ActionButton
                type="submit"
                className="full-width offset"
                disabled={!validateReset()}
                label={buttonText}></ActionButton>
            </form>
          </>
        )}
        {successful && (
          <>
            <span>
              Congratulations! Your Hub credentials for <strong>{email}</strong> was successfully updated.
              <br />
              <br />
              Lets sign in to test out your new credentials!
            </span>
            <ActionButton
              type="submit"
              className="full-width offset"
              label="Sign In"
              onClick={() => {
                history('/Login');
              }}></ActionButton>
          </>
        )}
      </div>
    </div>
  );
}
