import { useEffect, useState } from 'react';
import './Setup.scss';
import '../../styles/box.scss';
import Authenticate from './components/Authenticate';
import { StateInterface } from '../../interfaces/Identity.d';
import Menu from './components/Menu';
import Add from './components/Add';
import Verify from './components/Verify';
import Delete from './components/Delete';
import Questions from './components/Questions';
import Change from './components/Change';

export default function Start() {
  const [mode, setMode] = useState<number>(0);
  const [password, setPassword] = useState<string>('');
  const [state, setState] = useState<StateInterface | null>(null);

  useEffect(() => {
    document.title = 'BraveGen | Setup';
  }, []);

  return (
    <div className="box">
      <img src="/resources/Lion-Full.png" className="animate" alt="BraveGen" />
      <div className="inner animate setup">
        <>
          {mode === 0 && (
            <Authenticate
              onComplete={(pwd, st) => {
                setPassword(pwd);
                setState(st);
                setMode(1);
              }}
            />
          )}
          {mode === 1 && (
            <Menu
              password={password}
              state={state}
              onChange={(m) => {
                setMode(m);
              }}
            />
          )}
          {mode === 2 && (
            <Add
              password={password}
              state={state}
              onChange={(m, s) => {
                setState(s);
                setMode(m);
              }}
            />
          )}
          {mode === 3 && (
            <Verify
              password={password}
              onChange={(m) => {
                setMode(m);
              }}
            />
          )}
          {mode === 4 && (
            <Delete
              password={password}
              state={state}
              onChange={(m, s) => {
                setState(s);
                setMode(m);
              }}
            />
          )}
          {mode === 5 && (
            <Questions
              password={password}
              state={state}
              onChange={(m, s) => {
                setState(s);
                setMode(m);
              }}
            />
          )}
          {mode === 6 && (
            <Change
              password={password}
              state={state}
              onChange={(m) => {
                setMode(m);
              }}
            />
          )}
        </>
      </div>
    </div>
  );
}
