import { useEffect, useState } from 'react';
import { useFormFields } from '../../helpers/FormFields';
import { IdentityService } from '../../services/Identity.Service';
import ActionButton from '../../components/Buttons/ActionButton';
import { Link } from 'react-router-dom';

export default function Forgot() {
  const [fields, handleFieldChange] = useFormFields({ email: '' });
  const [buttonText, setButtonText] = useState<string>('Begin Recovery');
  const [errorText, setErrorText] = useState<string>('');
  const [showButton, setShowButton] = useState<boolean>(true);

  useEffect(() => {
    document.title = 'BraveGen | Forgot Password';
  }, []);

  function validateForgot() {
    return fields.email.length > 0;
  }

  async function handleForgot(event: any) {
    event.preventDefault();

    //Reset and loading state.
    setButtonText('Processing...');
    setErrorText('');

    const result = await IdentityService.forgot(fields.email);

    if (result) {
      setErrorText(
        "Excellent! If you have a registered account with us, you'll recieve the recovery email in your inbox."
      );
      setShowButton(false);
    } else {
      setErrorText("Oh dear, it seems we're unable to process your request at this time due to an error.");
      setButtonText('Begin Recovery');
    }
  }

  const greeting = () => {
    var time = new Date().getHours();

    if (time < 12) {
      return 'Good Morning!';
    }
    if (time >= 17) {
      return 'Good Evening!';
    }
    if (time >= 12) {
      return 'Good Afternoon!';
    }
    return '';
  };

  return (
    <div className="box">
      <img src="/resources/Lion-Full.png" className="animate" alt="BraveGen" />
      <div className="inner animate login">
        <h1>{greeting()}</h1>
        <span>Confirm the email associated with your account and we'll send a recovery verification email.</span>
        <form onSubmit={handleForgot}>
          <div className="group">
            <p>Email Address:</p>
            <input
              id="email"
              className="input"
              autoFocus
              type="email"
              value={fields.email}
              onChange={handleFieldChange}
            />
          </div>
          <span className="error">{errorText}</span>
          <p className="forgot">
            <Link to="/Login">Back to Login</Link>
          </p>

          {showButton && (
            <>
              <ActionButton
                type="submit"
                className="full-width offset"
                disabled={!validateForgot()}
                label={buttonText}></ActionButton>
            </>
          )}
        </form>
      </div>
    </div>
  );
}
