import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './TriggerButton.scss';

export default function TriggerButton({
  label = '',
  theme = 'default',
  icon,
  disabled = false,
  onClick,
  ...props
}: {
  label?: string;
  theme?: string;
  icon?: IconDefinition;
  disabled?: boolean | undefined;
  onClick?: any;
}) {
  return (
    <button
      className={`button trigger ${theme} ${disabled ? 'disabled' : ''}`}
      disabled={disabled}
      onClick={onClick}
      {...props}>
      {icon ? (
        <i className={label ? 'pad' : ''}>
          <FontAwesomeIcon icon={icon} />
        </i>
      ) : (
        <></>
      )}
      {label ? <span>{label}</span> : <></>}
    </button>
  );
}
