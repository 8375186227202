import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { useContext } from 'react';
import { IdentityContext } from './state/Identity.Context';
import Login from './views/Login/Login';
import Start from './views/Setup/Start';
import Challenge from './views/Challenge/Challenge';
import Welcome from './views/Welcome/Welcome';
import SignOut from './views/SignOut/SignOut';
import Forgot from './views/Forgot/Forgot';
import Reset from './views/Reset/Reset';

export default function ViewRoutes() {
  const identity = useContext(IdentityContext);

  const ProtectedRoutes = ({ isAuth }: { isAuth: boolean }) => {
    return isAuth ? <Outlet /> : <Navigate to={`/Login`} />;
  };

  const UnProtectedRoutes = ({ isAuth }: { isAuth: boolean }) => {
    return isAuth ? <Navigate to="/" /> : <Outlet />;
  };

  return (
    <Routes>
      <Route element={<ProtectedRoutes isAuth={identity.isAuth} />}>
        <Route path="/Setup" element={<Start />} />
        <Route path="/SignOut" element={<SignOut />} />
        <Route path="*" element={<Welcome />} />
      </Route>
      <Route element={<UnProtectedRoutes isAuth={identity.isAuth} />}>
        <Route path="/Login" element={<Login />} />
        <Route path="/Forgot" element={<Forgot />} />
        <Route path="/Reset" element={<Reset />} />
        <Route path="/Challenge" element={<Challenge />} />
        <Route path="*" element={<Navigate to="/Login" />} />
      </Route>
    </Routes>
  );
}
