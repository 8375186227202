import React, { useEffect } from 'react';
import { IdentityInterface } from '../interfaces/Identity.d';
import { IdentityService } from '../services/Identity.Service';

export const useIdentity = (): IdentityInterface => {
  const ref = new URLSearchParams(window.location.search).get('ref');
  const [loading, setLoading] = React.useState(true);
  const [isAuth, setIsAuth] = React.useState(false);
  const [token, setToken] = React.useState<string | null>(null);

  const setIdentity = React.useCallback((loading: boolean, auth: boolean, tok: string | null): void => {
    setLoading(loading);
    setIsAuth(auth);
    setToken(tok);
  }, []);

  useEffect(() => {
    //Set State
    setLoading(true);
    setIsAuth(false);

    //Save Ref, if specified.
    if (ref) {
      localStorage.setItem('ref', ref);
    }

    //Get current state
    var current = IdentityService.getToken();

    if (current) {
      //Lookup
      setIsAuth(true);
      setToken(current);
      setLoading(false);
    } else {
      //Purge
      IdentityService.expungeToken();
      setToken(null);
      setIsAuth(false);
      setLoading(false);
    }
  }, [ref]);

  return {
    loading,
    isAuth,
    token,
    setIdentity,
  };
};
