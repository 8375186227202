import QRCode from 'react-qr-code';
import TriggerButton from '../../../components/Buttons/TriggerButton';
import { StateInterface } from '../../../interfaces/Identity.d';
import { useState } from 'react';
import { IdentityService } from '../../../services/Identity.Service';

export default function Add({
  password,
  state,
  onChange,
}: {
  password: string;
  state: StateInterface | null;
  onChange: (mode: number, state: StateInterface | null) => void;
}) {
  const [buttonText, setButtonText] = useState<string>('Next');

  async function saveMFA() {
    setButtonText('Processing...');

    const result = await IdentityService.createMFA(password, `${state?.secret}`, 1);

    if (result) {
      //Successful - update the state
      onChange(3, {
        result: 1,
        errorMessage: '',
        email: `${state?.email}`,
        secret: `${state?.secret}`,
        type: 1,
        questions: state?.questions ?? [],
      });
    } else {
      setButtonText('Next');
    }
  }

  return (
    <>
      <span className="no-pad">Follow these steps below to add two factor authentication to your account.</span>
      <div className="line"></div>
      <h2>1. Open your authenticator app</h2>
      <span className="left">
        On your smartphone, open the <strong>Microsoft Authenticator</strong> app.
      </span>
      <div className="links">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://app.adjust.com/e3rxkc_7lfdtm?fallback=https%3A%2F%2Fitunes.apple.com%2Fus%2Fapp%2Fmicrosoft-authenticator%2Fid983156458">
          <img src="/resources/AppStoreBadge.svg" alt="Apple App Store" />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://app.adjust.com/e3rxkc_7lfdtm?fallback=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.azure.authenticator">
          <img src="/resources/GooglePlayStoreBadge.svg" alt="Google Play Store" />
        </a>
      </div>
      <div className="line"></div>
      <h2>2. Add an account and scan the code below</h2>
      <span className="left">
        Tap "+" to add a new account and scan the QR code displayed below with the{' '}
        <strong>Microsoft Authenticator</strong> app.
      </span>
      <div className="qr">
        <QRCode
          size={110}
          value={`otpauth://totp/BraveGen:${state?.email}?secret=${state?.secret}&issuer=BraveGen&period=30`}
        />
      </div>
      <div className="line"></div>
      <span>
        When you're ready, click <strong>Next</strong> to verify your setup.
      </span>
      <div className="actions">
        <TriggerButton
          label={buttonText}
          theme="default"
          disabled={false}
          onClick={() => {
            saveMFA();
          }}></TriggerButton>
        <TriggerButton
          label="Cancel"
          theme="light"
          disabled={false}
          onClick={() => {
            onChange(1, state);
          }}></TriggerButton>
      </div>
    </>
  );
}
